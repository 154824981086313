// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-information-js": () => import("./../../../src/pages/account-information.js" /* webpackChunkName: "component---src-pages-account-information-js" */),
  "component---src-pages-actions-js": () => import("./../../../src/pages/actions.js" /* webpackChunkName: "component---src-pages-actions-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-evaluation-js": () => import("./../../../src/pages/evaluation.js" /* webpackChunkName: "component---src-pages-evaluation-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lessons-js": () => import("./../../../src/pages/lessons.js" /* webpackChunkName: "component---src-pages-lessons-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-not-found-js": () => import("./../../../src/pages/not-found.js" /* webpackChunkName: "component---src-pages-not-found-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-program-finished-js": () => import("./../../../src/pages/program-finished.js" /* webpackChunkName: "component---src-pages-program-finished-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-progress-js": () => import("./../../../src/pages/progress.js" /* webpackChunkName: "component---src-pages-progress-js" */),
  "component---src-pages-quiz-about-js": () => import("./../../../src/pages/quiz-about.js" /* webpackChunkName: "component---src-pages-quiz-about-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-subscription-js": () => import("./../../../src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-toddler-talk-assessment-findings-js": () => import("./../../../src/pages/toddler-talk-assessment-findings.js" /* webpackChunkName: "component---src-pages-toddler-talk-assessment-findings-js" */),
  "component---src-pages-toddler-talk-plan-js": () => import("./../../../src/pages/toddler-talk-plan.js" /* webpackChunkName: "component---src-pages-toddler-talk-plan-js" */),
  "component---src-pages-toddler-talk-plan-tutorial-js": () => import("./../../../src/pages/toddler-talk-plan-tutorial.js" /* webpackChunkName: "component---src-pages-toddler-talk-plan-tutorial-js" */),
  "component---src-pages-update-password-js": () => import("./../../../src/pages/update-password.js" /* webpackChunkName: "component---src-pages-update-password-js" */),
  "component---src-templates-lesson-template-js": () => import("./../../../src/templates/lesson-template.js" /* webpackChunkName: "component---src-templates-lesson-template-js" */)
}

